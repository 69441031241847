import type { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

const List: FC<PropsWithChildren> = ({ children }) => {
  return <StyledList>{children}</StyledList>
}

export default List

const StyledList = styled.ul`
  ${({ theme }) => theme.typography.pMedium};
  padding-left: 0;
  color: ${({ color }) => color};
  margin: 8px 0;
  list-style-position: inside;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
