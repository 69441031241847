import styled from '@emotion/styled'

import type { FC, MouseEventHandler } from 'react'

import ButtonText from '@segment/matcha/components/ButtonText'
import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'

import CardBase, * as Card from '@segment/matcha/recipes/Card'

interface SpeakerCardProps {
  name: string
  title: string
  company: string
  image: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const SpeakerCard: FC<SpeakerCardProps> = ({ name, title, company, image, onClick }) => {
  return (
    <StyledCard tag="button" onClick={onClick}>
      <CardImageWrapper>
        <CardImage src={image} alt={name} />
      </CardImageWrapper>
      <Card.Body>
        <Card.Copy>
          <Card.Headline tag="h3">{name}</Card.Headline>
          <Card.Description>
            {title}, {company}
          </Card.Description>
        </Card.Copy>
        <Card.Action>
          <ButtonText tag="span" size="small" iconEnd={ChevronRightIcon}>
            See their bio
          </ButtonText>
        </Card.Action>
      </Card.Body>
    </StyledCard>
  )
}

const CardImageWrapper = styled.div`
  --transition: 0.5s ease-in-out;
  grid-area: image;
  width: calc(100% - 64px);
  max-width: 250px;
  height: auto;
  margin: 24px auto 0;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  ::before {
    display: block;
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 50%;
    border: 8px solid #f22f46;
    z-index: 1;
    opacity: 0;
    transition: opacity var(--transition);
  }

  ${({ theme }) => theme.breakpoints.up(`md`)} {
    margin: 32px auto 0;
  }
`

const CardImage = styled(Card.Image)`
  grid-area: none;
  height: 100%;
  width: 100%;
  transition: transform var(--transition);
  margin: 0;
  padding: 0;
`

const StyledCard = styled(CardBase)`
  text-align: left;
  cursor: pointer;

  &:hover {
    ${CardImage} {
      transform: scale(1.1);
    }

    ${CardImageWrapper}::before {
      opacity: 1;
    }
  }
`

export default SpeakerCard
