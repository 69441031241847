import type { FC } from 'react'
import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import type { AnimationItem } from 'lottie-web'

import Typography from '@segment/matcha/components/Typography'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import useIsInView from '@segment/utils/useInView'
import useLottie from '@segment/utils/useLottie/useLottie'

const OVERLINE = `About CDP Week`
const HEADING = `Maximize your experience`
const description = [
  `Companies who win, win with personalization. But precise engagement is hard to deliver. Just ask Gartner, whose research highlights that $96B is wasted every year on solutions to drive tailored customer experiences.`,
  `The next era of customer engagement is here, and we gathered industry experts to share their experiences from the frontlines.`,
  `Whether you're looking to enhance customer trust, improve operational efficiencies, or drive innovation, our sessions showcased how it’s never been easier to deliver personalized engagement within the AI era.`,
]

const AboutCDPWeek: FC = () => {
  const [animationData] = useState(() => import(`./assets/speakers-animation`).then((module) => module.default))

  const [animation, containerRef] = useLottie<AnimationItem, HTMLDivElement>({
    options: {
      autoplay: false,
      animationData,
      loop: true,
    },
  })
  const isInView = useIsInView(containerRef, { stopOnIntersection: false })

  useEffect(() => {
    if (isInView && animation) {
      animation.playSegments([0, 535], true)
      animation.addEventListener(`enterFrame`, () => {
        if (animation.currentFrame >= 91) {
          animation.playSegments([91, 535], false)
        }
      })
    } else {
      animation?.stop()
    }
  }, [animation, isInView])

  return (
    <Wrapper>
      <SectionBase variant="2Col">
        <Section.Copy>
          <CopyTextBase>
            <CopyText.Overline>{OVERLINE}</CopyText.Overline>
            <CopyText.Heading tag="h2">{HEADING}</CopyText.Heading>
          </CopyTextBase>
          {description.map((el) => (
            <Typography key={el} variant="pLarge">
              {el}
            </Typography>
          ))}
        </Section.Copy>

        <Animation ref={containerRef} />
      </SectionBase>
    </Wrapper>
  )
}

export default AboutCDPWeek

const Wrapper = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${transparentize(0.76, theme.palette.neutrals.black[100])} 0%, ${transparentize(
      0.76,
      theme.palette.neutrals.black[100]
    )} 100%), ${theme.palette.cdpWeek2023.gray[100]}`};
`

export const Animation = styled.div`
  width: 110%;
`
