import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

import { FontProvider } from '@segment/matcha/foundations/FontContext'
import { whitneyFont } from '@segment/matcha/foundations/Theme'
import { AppearanceProvider } from '@segment/matcha/foundations/AppearanceContext'

import { useCdpWeekSpeakerLandingPageQuery } from '@segment/api/routes/cdpWeek2024/queries'

import { PageConfigProvider, usePageConfig } from '@segment/contexts/page'

import Layout from '@segment/next-shared/layout'

import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import SaveYourSpot from '../components/SaveYourSpot/SaveYourSpot'
import CarouselSection from '../components/CarouselSection'

import OgImage from '../assets/OgImageCDPWeek2024.png'

import Hero from './components/Hero'
import AboutCDPWeek from './components/AboutCDPWeek'
import Plan from './components/Plan'

import MeetOurSpeakers from './components/MeetOurSpeakers'

// CdpWeek2024 page is hosted on the different domain then the rest of the app
// so we don't want to use an absolute urls for the links
const customPageConfig = {
  baseUrl: ``,
}

const CdpWeek2024View = () => {
  const theme = useTheme()
  const defaultPageConfig = usePageConfig()
  const pageConfig = {
    ...defaultPageConfig,
    ...customPageConfig,
  }

  const { data: speakers } = useCdpWeekSpeakerLandingPageQuery({ args: [{ speakersLimit: 3 }] })

  return (
    <FontProvider font={whitneyFont}>
      <PageConfigProvider value={pageConfig}>
        <AppearanceProvider appearance="cdpWeek2023">
          <Layout
            pageTitle="CDP Week | Twilio Segment"
            pageDescription="CDP Week is the premier industry conference for customer data leaders."
            bodyBackground={theme.palette.cdpWeek2023.gray[100]}
            pageOG={OgImage}
            canonicalUrl="https://cdpweek.com/"
          >
            <Navigation />
            <Main>
              <Hero />
              <AboutCDPWeek />
              <Plan />
              <CarouselSection />
              <MeetOurSpeakers speakers={speakers} />
              <SaveYourSpot />
            </Main>
            <Footer />
          </Layout>
        </AppearanceProvider>
      </PageConfigProvider>
    </FontProvider>
  )
}

const Main = styled.main`
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.cdpWeek2023.gray[100]};
`

export default CdpWeek2024View
