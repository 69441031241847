import styled from '@emotion/styled'
import type { FC, PropsWithChildren } from 'react'

const Item: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledItem>
      <ContentWrapper>{children}</ContentWrapper>
    </StyledItem>
  )
}

export default Item

const StyledItem = styled.li`
  ${({ theme }) => theme.typography.pSmall.css};
  white-space: nowrap;
  max-width: 100%;
  margin: 8px 0;
  color: ${({ theme }) => theme.palette.cdpWeek2023.gray[10]};

  &::marker {
    margin-right: 10px;
    color: ${({ theme }) => theme.palette.cdpWeek2023.mint[100]};
  }
`

const ContentWrapper = styled.div`
  white-space: normal;
  vertical-align: top;
  display: inline-block;
  width: calc(100% - 32px);
  p {
    margin: 0;
  }
`
