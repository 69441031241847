import { useState, useEffect } from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import Link from 'next/link'
import type { AnimationItem } from 'lottie-web'

import Button from '@segment/matcha/components/Button'

import HeroBase, * as HeroRecipe from '@segment/matcha/recipes/Hero'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import useLottie from '@segment/utils/useLottie/useLottie'
import useIsInView from '@segment/utils/useInView'

import useDomainPath from '../../../utils/useDomainPath'

import jsonUrl from './assets/hero-animation.json.data'

const HEADING = `Reimagine Intelligent Engagement in the AI Era`
const DESCRIPTION = `During this three-day virtual summit, industry leaders from Fidelity, IBM, Google Cloud, and more shared how they are making every customer interaction personal. With intelligent engagement, real-time context, and AI-driven workflows, you’ll learn how to increase revenue, optimize conversions and build stronger customer relationships.`

const button = {
  text: `Watch Now`,
}

const Hero: FC = () => {
  const registerLink = useDomainPath(`register`)

  useEffect(() => {
    setAnimationData(fetch(jsonUrl))
  }, [])

  const [animationData, setAnimationData] = useState<Promise<Response>>()
  const [animation, containerRef] = useLottie<AnimationItem, HTMLDivElement>({
    options: {
      animationData,
      autoplay: true,
      loop: false,
    },
  })
  const isInView = useIsInView(containerRef)

  useEffect(() => {
    if (isInView && animation) {
      animation.playSegments([0, animation.totalFrames], true)
      animation.addEventListener(`enterFrame`, () => {
        if (animation.currentFrame >= 105) {
          animation.playSegments([105, animation.totalFrames], false)
        }
      })
    } else {
      animation?.stop()
    }
  }, [animation, isInView])

  return (
    <Wrapper>
      <StyledHero variant="2Col">
        <HeroRecipe.Copy>
          <CopyTextBase>
            <CopyTextHeading variant="h1" tag="h1">
              {HEADING}
            </CopyTextHeading>
            <ButtonWrapper>
              <Link href={registerLink}>
                <Button variant="filled" size="large" color="primary">
                  {button.text}
                </Button>
              </Link>
            </ButtonWrapper>
            <CopyText.Description tag="p" variant="pLarge">
              {DESCRIPTION}
            </CopyText.Description>
          </CopyTextBase>
        </HeroRecipe.Copy>
        <HeroRecipe.Contents>
          <Animation ref={containerRef} />
        </HeroRecipe.Contents>
      </StyledHero>
    </Wrapper>
  )
}

export default Hero

const CopyTextHeading = styled(CopyText.Heading)`
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    max-width: 480px;
  }
`
const Animation = styled.div`
  width: 120%;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    width: 110%;
  }
`

const StyledHero = styled(HeroBase)`
  ${HeroRecipe.Wrapper} {
    gap: 100px;
    padding-top: 0;
    padding-bottom: 0;

    ${({ theme }) => theme.breakpoints.down(`md`)} {
      gap: 40px;
      grid-template-areas:
        'contents'
        'copy';
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-bottom: 40px;
  }
`

const ButtonWrapper = styled.div`
  margin: 32px 0;
`
