import { createPortal } from 'react-dom'
import type { MouseEventHandler, ReactNode } from 'react'
import { forwardRef } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

import Image from '@segment/matcha/components/Image'
import ButtonText from '@segment/matcha/components/ButtonText'
import { ClearIcon, ChevronRightIcon } from '@segment/matcha/components/Icons/Common'
import Typography from '@segment/matcha/components/Typography'

import ModalBase, * as Modal from '@segment/matcha/components/Modal'

import useLocalDate from 'views/CdpWeek2024/utils/useLocalDate'

import usePortal from '@segment/utils/usePortal'
import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import useDomainPath from '../../utils/useDomainPath'

interface SpeakerModalProps {
  opened: boolean
  speaker: {
    name: string
    title: string
    company: string
    image: string
    sessionTitle: string
    sessionDate: string
  } | null
  children: ReactNode
  onCloseClick?: MouseEventHandler<HTMLButtonElement>
}

const BUTTON_TEXT = `View the agenda`

const SpeakerModal = forwardRef<HTMLDivElement, SpeakerModalProps>(
  ({ speaker, opened, onCloseClick, children }, ref) => {
    const theme = useTheme()
    const pageConfig = usePageConfig()
    const modalsRoot = usePortal(`speaker-modal`)
    const localDate = useLocalDate(speaker?.sessionDate ?? ``)
    const agendaLink = useDomainPath(`agenda`)

    return (
      <>
        {opened &&
          speaker !== null &&
          createPortal(
            <ModalBase size="xlarge" ref={ref} placement="center">
              <Modal.Head>
                <Header>
                  <StyledImage src={speaker.image} alt={`Portrait of ${speaker.name}`} />

                  <Info>
                    <StyledTypography variant="h4">{speaker.name}</StyledTypography>
                    <StyledTypography variant="pMedium">
                      {speaker.title} {speaker.company}
                    </StyledTypography>
                  </Info>
                </Header>

                <CloseButton onClick={onCloseClick}>
                  <ClearIcon color={theme.palette.neutrals.white[100]} size="large" />
                </CloseButton>
              </Modal.Head>

              <Modal.Body>
                {children}

                <Typography variant="h5">{speaker.sessionTitle}</Typography>

                <StyledTypography variant="textBold" tokens={{ color: theme.palette.cdpWeek2023.saffron[100] }}>
                  {localDate}
                </StyledTypography>
              </Modal.Body>

              <Modal.Footer>
                <ButtonText
                  variant="secondary"
                  iconEnd={ChevronRightIcon}
                  size="small"
                  href={getAbsoluteUrl(agendaLink, pageConfig)}
                >
                  {BUTTON_TEXT}
                </ButtonText>
              </Modal.Footer>
            </ModalBase>,
            modalsRoot
          )}
      </>
    )
  }
)

const StyledTypography = styled(Typography)`
  margin: 0;
`

const CloseButton = styled.button`
  align-self: start;
  cursor: pointer;
`

const StyledImage = styled(Image)`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  margin-left: 4px;
  object-fit: cover;
`

const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
`

const Info = styled.div`
  display: grid;
  gap: 14px;
`

export default SpeakerModal
